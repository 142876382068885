
import { defineComponent, ref } from "vue";

import PsIcon from '@/components/core/icons/PsIcon.vue';
import PsDropdown from '@/components/core/dropdown/PsDropdown.vue';

import PsConfig from '@/config/PsConfig';
import PsLabel from "@/components/core/label/PsLabel.vue";
import $ from "cash-dom";
import { useI18n } from 'vue-i18n';
import { i18n } from '@/assets/locales/index';
import { PsValueProvider } from "@/store/modules/core/PsValueProvider";

export default defineComponent ({
    components : {
        PsIcon,
        PsDropdown,
        PsLabel,
    },
    props : {
        topOfPage : {
            type : Boolean,
            default : false 
        }
    },
    setup() {
        
        var psValueHolder = PsValueProvider.psValueHolder;
       
        var supportedLanguages = PsConfig.supportedLanguages;
        var activeLanguage = ref();
        if(psValueHolder.languageCode == '' || psValueHolder.languageCode== undefined){
            activeLanguage.value =  PsConfig.defaultLanguage;
       }else{
            for(var lan of supportedLanguages){
                if(lan.languageCode == psValueHolder.languageCode){
                    activeLanguage.value =  lan;
                }

            }  
        }

        //light / dark mode
        const isDarkMode = ref(true);
        function toggleDarkMode() {
            
            if(localStorage.isDarkMode != null && localStorage.isDarkMode == 'true') {
                localStorage.isDarkMode = 'false';
                isDarkMode.value = false;
            }else {
                localStorage.isDarkMode = 'true';
                isDarkMode.value = true;
            }
            loadIsDarkMode();
        }

        loadIsDarkMode();
        function loadIsDarkMode() {
            if(localStorage.isDarkMode != null && localStorage.isDarkMode == 'true') {
                $("html").addClass("dark");
                isDarkMode.value = true;
            }else {
                $("html").removeClass("dark");
                isDarkMode.value = false;
            }
        }
    

        //language selection
        const t = useI18n;
        function changeLanguage(language){
            // PsConfig.defaultLanguage = language;
            psValueHolder.replacelanguageCode(language.languageCode);    
            i18n.global.locale = language.languageCode;
            i18n.global.fallbackLocale = language.languageCode;
            activeLanguage.value = language;
        }
        
        return {
            isDarkMode,
            toggleDarkMode,
            activeLanguage,
            supportedLanguages,
            changeLanguage,
            t,
        }

    }
});
